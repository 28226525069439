import { FC } from "react";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { EditModeButton } from "../../../../components/UI/Buttons/EditModeButton/EditModeButton";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { ContractInput } from "./ui/ContractInput/ContractInput";
import { ContractDateInput } from "./ui/ContractDateInput/ContractDateInput";
import { NotificationAlerts } from "../../../../components/NotificationAlerts/NotificationAlerts";
import { ContractCheckbox } from "./ui/ContractCheckbox/ContractCheckbox";
import { priceTransormFormat } from "../../../../shared/functions/functions";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import { IContractBlock } from "./IContractBlock";
import { EmptyText } from "../../../../components/EmptyText/EmptyText";
import { RemoveItemButton } from "../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";
import { CopyIconButton } from "../../../../components/UI/Buttons/CopyIconButton/CopyIconButton";
import { AddedButton } from "../../../../components/UI/Buttons/AddedButton/AddedButton";

export const ContractBlock: FC<IContractBlock> = (props) => {
  const {
    isLoading,
    error,
    isShowError,
    isEdit,
    isAddAttach,
    isAddAdditionalAgreement,
    startEdit,
    closeEdit,
    removeAttachment,
    removeAdditionalAgreement,
    changeCheckbox,
    submit,
    onOpenAddAttach,
    onOpenAddAditionalAgreement,
    onCloseError,
    readOnly,
    contractBlock,
    changeSimpleEditContractBlock,
    editCheckbox,
    editContractBlock,
    editCheckboxNDS,
    changePostedCommentsWithEditContractBlock,
  } = props;
  return (
    <>
      <AsideCardLayout
        headerTitle="договор"
        rightContent={
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            {contractBlock === null && !isEdit && (
              <AddedButton variant="outlined" size="small" onClick={startEdit} disabled={readOnly}>
                добавить договор
              </AddedButton>
            )}
            {contractBlock !== null && (
              <EditModeButton
                isEdit={isEdit}
                onClose={closeEdit}
                onOpenEdit={startEdit}
                onSubmit={submit}
                loading={isLoading}
                disabledEdit={readOnly}
              />
            )}
            {contractBlock === null && isEdit && (
              <EditModeButton
                isEdit={isEdit}
                onClose={closeEdit}
                onOpenEdit={startEdit}
                onSubmit={submit}
                loading={isLoading}
                disabledEdit={readOnly}
              />
            )}
          </Stack>
        }
        sx={{ height: "fit-content" }}>
        <Box sx={{ mt: "6px" }}>
          {!isEdit && contractBlock === null && <EmptyText noWrap>Добавьте договор</EmptyText>}
          {!isEdit && contractBlock !== null && (
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "100%",
                    sm: "calc(50% - 6px) 50%",
                    md: "100%",
                    lg: "calc(50% - 6px) 50%",
                  },
                  rowGap: "6px",
                  columnGap: "6px",
                }}>
                <Typography>Сумма договора:</Typography>
                <Typography>
                  {contractBlock?.summ ? (
                    priceTransormFormat(contractBlock?.summ, true)
                  ) : (
                    <EmptyText />
                  )}
                </Typography>
                <Typography>
                  Договор №{contractBlock?.contract?.number} от{" "}
                  {dayjs(contractBlock?.contract?.date)?.format("DD.MM.YYYY")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Link
                    color={"secondary"}
                    component={RouterLink}
                    to={contractBlock?.contract?.link}
                    target="_blank"
                    sx={{ display: "inline-block" }}
                    noWrap>
                    {contractBlock?.contract?.link}
                  </Link>
                </Box>
                {contractBlock?.attachment && (
                  <>
                    <Typography>Сумма приложения:</Typography>
                    <Typography>
                      {contractBlock?.summAttachment ? (
                        priceTransormFormat(contractBlock?.summAttachment, true)
                      ) : (
                        <EmptyText />
                      )}
                    </Typography>
                    <Typography>
                      Приложение №{contractBlock?.attachment?.number} от{" "}
                      {dayjs(contractBlock?.attachment?.date)?.format("DD.MM.YYYY")}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Link
                        color={"secondary"}
                        component={RouterLink}
                        to={contractBlock?.attachment?.link}
                        target="_blank"
                        sx={{ display: "inline-block" }}
                        noWrap>
                        {contractBlock?.attachment?.link}
                      </Link>
                    </Box>
                  </>
                )}
                {contractBlock?.additionalAgreement && (
                  <>
                    <Typography>
                      Дополнительное соглашение №{contractBlock?.additionalAgreement?.number} от{" "}
                      {dayjs(contractBlock?.additionalAgreement?.date)?.format("DD.MM.YYYY")}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Link
                        color={"secondary"}
                        component={RouterLink}
                        to={contractBlock?.additionalAgreement?.link}
                        target="_blank"
                        sx={{ display: "inline-block" }}
                        noWrap>
                        {contractBlock?.additionalAgreement?.link}
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
              <Divider sx={{ borderStyle: "dashed", mt: "6px", mb: "6px" }} />
              <ContractCheckbox
                initValue={contractBlock?.isNDS || false}
                onChange={() => ""}
                text="НДС"
                readOnly
                field="isNDS"
              />
              <Divider sx={{ borderStyle: "dashed", mt: "6px", mb: "6px" }} />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "50% 50%",
                  columnGap: "6px",
                  alignItems: "center",
                }}>
                <Typography>Трек номер отслеживания:</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}>
                    {contractBlock?.trackNumber?.toUpperCase() || <EmptyText />}
                  </Typography>
                  {contractBlock?.trackNumber && (
                    <CopyIconButton copyText={contractBlock?.trackNumber || ""} />
                  )}
                </Stack>
                <Box>
                  <ContractCheckbox
                    initValue={contractBlock?.isOriginalsReceived || false}
                    onChange={() => ""}
                    text="Оригиналы получены"
                    readOnly
                    field="isOriginalsReceived"
                  />
                </Box>
              </Box>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography>Комментарий:</Typography>
                {contractBlock?.contract?.posted_comments.length ? (
                  <Typography overflow={"hidden"}>
                    {contractBlock?.contract?.posted_comments[0].text}
                  </Typography>
                ) : (
                  <EmptyText>отсутствует</EmptyText>
                )}
              </Stack>
            </Box>
          )}
          {isEdit && (
            <Stack spacing={"6px"}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography>Сумма договора:</Typography>
                <ContractInput
                  field="summ"
                  value={contractBlock?.summ?.toString() || ""}
                  onChange={changeSimpleEditContractBlock}
                  placeholder="Введите сумму"
                  inputType="number"
                  disabled={isLoading}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "start", sm: "center" }}
                justifyContent={"space-between"}
                spacing={{ xs: "6px", sm: 0 }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    justifyContent: { xs: "space-between", sm: "auto" },
                  }}>
                  <Typography sx={{ whiteSpace: "nowrap" }}>
                    Договор №
                    {isEdit ? (
                      <Typography component="span" color={"error"}>
                        *
                      </Typography>
                    ) : null}
                  </Typography>
                  <ContractInput
                    field="number"
                    value={contractBlock?.contract?.number || ""}
                    type="contract"
                    onChange={changeSimpleEditContractBlock}
                    placeholder="номер"
                    disabled={isLoading}
                    sx={{
                      maxWidth: { xs: "auto", sm: "80px" },
                      minWidth: { xs: "auto", sm: "80px" },
                    }}
                    id={contractBlock?.contract?.id}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={"6px"}
                  sx={{ width: { xs: "100%", sm: "auto" } }}>
                  <Typography>
                    от
                    {isEdit ? (
                      <Typography component="span" color={"error"}>
                        *
                      </Typography>
                    ) : null}
                  </Typography>
                  <ContractDateInput
                    value={contractBlock?.contract?.date || ""}
                    field="date"
                    type="contract"
                    sx={{ maxWidth: "174px" }}
                    onChange={changeSimpleEditContractBlock}
                    placeholder="дд.мм.год"
                    disabled={isLoading}
                    id={contractBlock?.contract?.id}
                  />
                </Stack>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography>Ссылка на договор:</Typography>
                <ContractInput
                  field="link"
                  type="contract"
                  value={contractBlock?.contract?.link || ""}
                  onChange={changeSimpleEditContractBlock}
                  placeholder="Введите ссылку"
                  disabled={isLoading}
                  id={contractBlock?.contract?.id}
                />
              </Stack>
              {!editContractBlock?.attachment && !isAddAttach && (
                <Box>
                  <AddedButton
                    variant="outlined"
                    onClick={onOpenAddAttach}
                    size="small"
                    disabled={isLoading}>
                    добавить приложение
                  </AddedButton>
                </Box>
              )}
              {(editContractBlock?.attachment || isAddAttach) && (
                <>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"}>Приложение</Typography>
                    <RemoveItemButton onClick={removeAttachment} disabled={isLoading} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>Сумма приложения:</Typography>
                    <ContractInput
                      field="summAttachment"
                      value={contractBlock?.summAttachment?.toString() || ""}
                      onChange={changeSimpleEditContractBlock}
                      placeholder="Введите сумму"
                      inputType="number"
                      disabled={isLoading}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "start", sm: "center" }}
                    justifyContent={"space-between"}
                    spacing={{ xs: "6px", sm: 0 }}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={"6px"}
                      sx={{
                        width: { xs: "100%", sm: "auto" },
                        justifyContent: { xs: "space-between", sm: "auto" },
                      }}>
                      <Typography sx={{ whiteSpace: "nowrap" }}>
                        Приложение №
                        {isEdit ? (
                          <Typography component="span" color={"error"}>
                            *
                          </Typography>
                        ) : null}
                      </Typography>
                      <ContractInput
                        field="number"
                        type="attachment"
                        value={contractBlock?.attachment?.number || ""}
                        onChange={changeSimpleEditContractBlock}
                        placeholder="номер"
                        disabled={isLoading}
                        sx={{
                          maxWidth: { xs: "auto", sm: "80px" },
                          minWidth: { xs: "auto", sm: "80px" },
                        }}
                        id={contractBlock?.attachment?.id}
                      />
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      sx={{ width: { xs: "100%", sm: "auto" } }}>
                      <Typography>
                        от
                        {isEdit ? (
                          <Typography component="span" color={"error"}>
                            *
                          </Typography>
                        ) : null}
                      </Typography>
                      <ContractDateInput
                        value={contractBlock?.attachment?.date || ""}
                        field="date"
                        type="attachment"
                        onChange={changeSimpleEditContractBlock}
                        placeholder="дд.мм.год"
                        disabled={isLoading}
                        id={contractBlock?.attachment?.id}
                        sx={{ maxWidth: "174px" }}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>Ссылка на приложение:</Typography>
                    <ContractInput
                      field="link"
                      type="attachment"
                      value={contractBlock?.attachment?.link || ""}
                      onChange={changeSimpleEditContractBlock}
                      placeholder="Введите ссылку"
                      disabled={isLoading}
                      id={contractBlock?.attachment?.id}
                    />
                  </Stack>
                </>
              )}
              {!editContractBlock?.additionalAgreement && !isAddAdditionalAgreement && (
                <Box>
                  <AddedButton
                    variant="outlined"
                    onClick={onOpenAddAditionalAgreement}
                    size="small"
                    disabled={isLoading}>
                    добавить доп.соглашение
                  </AddedButton>
                </Box>
              )}
              {(editContractBlock?.additionalAgreement || isAddAdditionalAgreement) && (
                <>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"}>Дополнительное соглашение</Typography>
                    <RemoveItemButton onClick={removeAdditionalAgreement} disabled={isLoading} />
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "start", sm: "center" }}
                    justifyContent={"space-between"}
                    spacing={{ xs: "6px", sm: 0 }}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={"6px"}
                      sx={{
                        width: { xs: "100%", sm: "auto" },
                        justifyContent: { xs: "space-between", sm: "auto" },
                      }}>
                      <Typography sx={{ whiteSpace: "nowrap" }}>
                        Доп. соглашение №
                        {isEdit ? (
                          <Typography component="span" color={"error"}>
                            *
                          </Typography>
                        ) : null}
                      </Typography>
                      <ContractInput
                        field="number"
                        type="additionalAgreement"
                        value={contractBlock?.additionalAgreement?.number || ""}
                        onChange={changeSimpleEditContractBlock}
                        placeholder="номер"
                        disabled={isLoading}
                        sx={{
                          maxWidth: { xs: "auto", sm: "80px" },
                          minWidth: { xs: "auto", sm: "80px" },
                        }}
                        id={contractBlock?.additionalAgreement?.id}
                      />
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      sx={{ width: { xs: "100%", sm: "auto" } }}>
                      <Typography>
                        от
                        {isEdit ? (
                          <Typography component="span" color={"error"}>
                            *
                          </Typography>
                        ) : null}
                      </Typography>
                      <ContractDateInput
                        value={contractBlock?.additionalAgreement?.date || ""}
                        field="date"
                        type="additionalAgreement"
                        onChange={changeSimpleEditContractBlock}
                        placeholder="дд.мм.год"
                        disabled={isLoading}
                        id={contractBlock?.additionalAgreement?.id}
                        sx={{ maxWidth: "174px" }}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>
                      Ссылка на доп. соглашение:
                    </Typography>
                    <ContractInput
                      field="link"
                      type="additionalAgreement"
                      value={contractBlock?.additionalAgreement?.link || ""}
                      onChange={changeSimpleEditContractBlock}
                      placeholder="Введите ссылку"
                      disabled={isLoading}
                      id={contractBlock?.additionalAgreement?.id}
                    />
                  </Stack>
                </>
              )}
              <Divider sx={{ borderStyle: "dashed" }} />
              <Box>
                <ContractCheckbox
                  initValue={editCheckboxNDS || false}
                  onChange={changeCheckbox as any}
                  disabled={isLoading}
                  text="НДС"
                  readOnly={false}
                  field="isNDS"
                />
              </Box>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography>Трек номер отслеживания:</Typography>
                <ContractInput
                  field="trackNumber"
                  value={contractBlock?.trackNumber?.toString() || ""}
                  onChange={changeSimpleEditContractBlock}
                  placeholder="Введите трек номер"
                  disabled={isLoading}
                  textCase={"uppercase"}
                />
              </Stack>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography>Комментарий:</Typography>
                <ContractInput
                  id={contractBlock?.contract?.id}
                  field="posted_comments"
                  type="contract"
                  value={
                    contractBlock?.contract?.posted_comments.length
                      ? contractBlock?.contract?.posted_comments[0].text
                      : ""
                  }
                  onChange={changePostedCommentsWithEditContractBlock}
                  placeholder="Введите комментарий"
                  disabled={isLoading}
                  sx={{ width: "100%", ml: 1 }}
                />
              </Stack>
              <Box>
                <ContractCheckbox
                  initValue={editCheckbox || false}
                  onChange={changeCheckbox as any}
                  disabled={isLoading}
                  text="Оригиналы получены"
                  readOnly={false}
                  field="isOriginalsReceived"
                />
              </Box>
            </Stack>
          )}
        </Box>
      </AsideCardLayout>
      {isShowError && onCloseError && (
        <NotificationAlerts
          error={{ text: error ? error : "", open: isShowError, onClose: onCloseError }}
        />
      )}
    </>
  );
};
