import { FC, memo, Fragment } from "react";
import Box from "@mui/material/Box";
import { IArrayField } from "./IArrayField";
import { priceTransormFormat } from "../../../../../shared/functions/functions";
import { Link, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { SocialType } from "../SocialType/SocialType";
import { PaymentStatus } from "../PaymentStatus/PaymentStatus";
import paymentsTableStore from "../../../../../store/paymentsTable.store";
import { CopyIconButton } from "../../../../UI/Buttons/CopyIconButton/CopyIconButton";

const ArrayField: FC<IArrayField> = memo((props) => {
  const { readOnly, restProps, cellProps } = props;

  if (restProps[cellProps.code].length === 0) return <Box key={cellProps.code}>—</Box>;

  return (
    <Fragment>
      {restProps[cellProps.code].map((item: any, ind: number) =>
        item ? (
          <Box
            key={ind + cellProps.code}
            sx={{
              height: "35px",
              display: "flex",
              alignItems: "center",
            }}>
            {cellProps.isMoneySimple && item ? (
              <Typography noWrap>{priceTransormFormat(+item, true, false, false)}</Typography>
            ) : null}
            {cellProps.code === "payment_integration_channel" && (
              <SocialType
                showPaymentOnCardIcon={item.isPaymentOnCard}
                code={item?.channel.type?.code}
                text={item?.channel.name}
                sxProps={{ maxWidth: 200 }}
              />
            )}
            {cellProps.isPaymentArrayStatus ? (
              <PaymentStatus
                id={restProps?.payment_pay_id[ind]}
                statusCode={item?.code}
                type={cellProps.code === "payment_integration_status" ? "integration" : "pay"}
                readOnly={cellProps.code === "payment_integration_status" || readOnly}
                wrap={false}
                update_id={
                  cellProps.code === "payment_integration_status" ? undefined : restProps?.update_id
                }
                onChangeStatus={
                  cellProps.code === "payment_integration_status"
                    ? undefined
                    : paymentsTableStore.changePaymentInTable
                }
                isShowPaymentModal={cellProps.isPaymentArrayStatus}
                paymentType={restProps?.payment_pay_paymentType[ind]}
                totalSumm={restProps?.payment_pay_totalSumm[ind]}
              />
            ) : null}
            {cellProps.isArrayLinks && (
              <Box>
                {item ? (
                  <Stack direction={"row"} alignItems={"center"}>
                    <Link
                      href={item}
                      target="_blank"
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: cellProps.maxWidth ? cellProps.maxWidth : "180px",
                        display: "block",
                        fontSize: "12px",
                      }}>
                      <Typography sx={{ textAlign: "left" }} noWrap>
                        {item}
                      </Typography>
                    </Link>
                    <CopyIconButton copyText={item} />
                  </Stack>
                ) : (
                  <Typography sx={{ textAlign: "left" }} noWrap>
                    —
                  </Typography>
                )}
              </Box>
            )}
            {cellProps.isPomokod && (
              <Typography sx={{ textAlign: "left", color: item?.isActivate ? "red" : "inherit" }}>
                {item?.code}
              </Typography>
            )}
            {cellProps.isDateArray && <Typography>{dayjs(item).format("DD.MM.YYYY")}</Typography>}
            {cellProps.isTextSimple && (
              <>
                <Typography sx={{ textAlign: "left" }} noWrap>
                  {item ? item : "—"}
                </Typography>
                {cellProps.isSimpleCopy && <CopyIconButton copyText={restProps[cellProps.code]} />}
              </>
            )}
          </Box>
        ) : (
          <Box
            key={ind + cellProps.code}
            sx={{ height: "40px", display: "flex", alignItems: "center" }}>
            <Typography key={item}>—</Typography>
          </Box>
        )
      )}
    </Fragment>
  );
});

export default ArrayField;
