import { FC } from "react";
import { IContractCheckbox } from "./IContractCheckbox";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { purple_color } from "../../../../../../app/styles/variables";
import { CustomIconButton } from "../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { Add } from "@mui/icons-material";

export const ContractCheckbox: FC<IContractCheckbox> = (props) => {
  const {
    initValue,
    onChange,
    disabled,
    text,
    readOnly = false,
    labelPlacement = "end",
    field,
    addButton,
  } = props;

  return (
    <FormControlLabel
      slotProps={{ typography: { sx: { cursor: readOnly ? "auto" : "pointer" } } }}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          checked={initValue}
          onChange={
            !readOnly
              ? field
                ? (e) => onChange(e?.target?.checked, field)
                : (e) => onChange(e?.target?.checked)
              : undefined
          }
          inputProps={{ style: { cursor: readOnly ? "auto" : "pointer" } }}
          disabled={disabled}
        />
      }
      label={
        <Box display={'flex'} columnGap={1} alignItems={'center'}>
          <Typography
            sx={{
              "&:hover": {
                color: readOnly ? "auto" : purple_color,
              },
            }}>
            {text}
          </Typography>
          {addButton !== undefined && <CustomIconButton size="small" showBackground  onClick={addButton}><Add fontSize="small"/></CustomIconButton>}
        </Box>
      }
      disabled={disabled}
      sx={{ cursor: readOnly ? "auto" : "pointer", ml: 0 }}
    />
  );
};
