import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import financeCardPageStore from "../../../../../../store/financeCardPage.store";
import { ContractCheckbox } from "../../../ContractBlock/ui/ContractCheckbox/ContractCheckbox";
import { ContractInput } from "../../../ContractBlock/ui/ContractInput/ContractInput";
import { RemoveItemButton } from "../../../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";

interface LinkItem {
  id: string;
  value: string;
}

interface ISelfEmployedCheck {
  isLoading: boolean;
  links: string[];
  setLinks: (links: LinkItem[]) => void;
}

export const SelfEmployedCheck: FC<ISelfEmployedCheck> = observer((props) => {
  const { isLoading, links, setLinks } = props;

  const stringLinksToObject = links?.map((link) => {
    return { id: Date.now().toString() + Math.random(), value: link };
  });

  const [linkObjects, setLinkObjects] = useState(stringLinksToObject);

  const handleChangeLink = (id: string, value: string) => {
    const updatedLinks = linkObjects.map((link) => (link.id === id ? { ...link, value } : link));

    setLinkObjects(updatedLinks);
  };

  const handleAddLink = () => {
    const newLink: LinkItem = { id: Date.now().toString() + Math.random(), value: "" };
    const newLinkObjects = [...linkObjects, newLink];
    setLinkObjects(newLinkObjects);
  };

  const handleRemoveLink = (id: string) => {
    const updatedLinks = linkObjects.filter((link) => link.id !== id);
    setLinkObjects(updatedLinks);
  };

  useEffect(() => {
    setLinks(linkObjects);
  }, [linkObjects]);

  useEffect(() => {
    if (links.length === 0) {
      handleAddLink();
    }
  }, []);

  return (
    <>
      <Box>
        <ContractCheckbox
          initValue={Boolean(financeCardPageStore.editHasSelfEmployedCheck)}
          onChange={financeCardPageStore.changeEditHasSelfEmployedCheck}
          readOnly={false}
          disabled={isLoading}
          text={linkObjects.length > 1 ? "Чеки от самозанятого" : "Чек от самозанятого"}
          addButton={handleAddLink}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        {linkObjects.map((link, index) => (
          <Box key={link.id} display="flex" alignItems="center">
            <Box width="100%" flexGrow={1}>
              <ContractInput
                field=""
                onChange={(e) => handleChangeLink(link.id, e.toString())}
                value={link.value}
                disabled={isLoading}
                placeholder="Ссылка на чек от самозанятого"
                fullWidth
              />
            </Box>
            {index !== 0 && (
              <RemoveItemButton onClick={(e) => {
                e.stopPropagation();
                handleRemoveLink(link.id);
              }} disabled={isLoading}/>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
});
