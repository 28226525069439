import {ISettingsSelected} from "../components/IntegrationTable/models/ISettingsSelected"
import {immutableTableField} from "../components/Table/models/TableFilds"
import {IStrategyField} from "./interface/IStrategyField"
import {allAvailableField} from "./strategy"

const allImmutableField: string[] = []

const sortFields = [
  'id', 'roi', 'likes', 'views', 'comments', 'dateFound', 'integration_date', 'prediction_views', 'prediction_orders',
  'prediction_traffic', 'dateGiven', 'createdAt', 'paidAt', 'channel_roi', 'channel_id', "lids", "canceledOrders",
  "averageOrderSumm", "traffic", "validOrders", 'channel_canceledOrders', "channel_traffic", 'minRequests', 'minOrders',
  'paidSum', 'prediction_cpv', 'prediction_cr', 'prediction_ctr', 'prediction_roi', 'tz_id', 'payment_id', 'payment_createdAt',
  'payment_plannedPaidAt', "payment_totalSumm", 'integration_plannedPaymentDate', 'integration_paidAt', 'payment_paidAt',
  'payment_counteragent_shortName', 'payment_integration_id', 'payment_pay_plannedPaidAt', 'productSampleInfo_sendAt', 'agreement_allDecisionDate'
]

const percentFields = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65_plus', 'woman', 'man', 'targetAudience', "subs", "notSubs", "fromPC", "fromPhone", 'roi', 'channel_roi']

export function defaultSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const maxWidthCodes = ['channel_name', 'comment']
  const enableRemains = ['minRequests', 'minOrders']
  const moneyCodes = ['totalPrice', 'totalOrdersSum', 'averageOrderPrice', 'payment_totalSumm', 'productSampleInfo_deliveryCost', 'productSampleInfo_refundCost']
  const roiCodes = ['roi', 'channel_roi', 'channel_roiPredict', 'channel_roiNewPredict', 'prediction_roi']
  const isMoneyShowPredictionRemains = ['productSampleInfo_deliveryCost', 'productSampleInfo_refundCost']
  const maxWidthCalc = (code: allAvailableField) => {
    if (roiCodes.includes(code)) return '80px'
    return maxWidthCodes.includes(code) ? '250px' : 'inherit'
  }

  const tableField: IStrategyField = {
    title: field.name,
    code: code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField),
    isSimple: true,
    minWidth: [immutableTableField.ID, immutableTableField.ROI].includes(field.code as immutableTableField) ? '70px' : 'inherit',
    isSort: sortFields.includes(field.code),
    isPercent: percentFields.includes(field.code),
    maxWidth: maxWidthCalc(code),
    isTooltip: code === 'comment',
    isShowRemains: enableRemains.includes(code) ? false : undefined,
    isMoneySimple: moneyCodes.includes(code),
    isMoneyShowPredictionRemains:isMoneyShowPredictionRemains.includes(code)
  }

  return tableField
}

export function promokodSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isPomokod: true
  }

  return tableField
}


export function statusSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isStatuses: true,
    isIntegrationPaymentStatus: code === 'integration_paymentStatus'
  }

  return tableField
}

export function channelSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const roiCodes = ['roi', 'channel_roi', 'channel_roiPredict', 'channel_roiNewPredict', 'prediction_roi']
  const sortCodes = ['channel','channel_name']

  const maxWidthCalc = (code: allAvailableField) => {
    if (roiCodes.includes(code)) return '75px'
    return '200px'
  }

  const tableField: IStrategyField = {
    title: field.name,
    code: code === 'channel_name' ? 'name' : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isChannel: true,
    maxWidth: maxWidthCalc(code),
    isSort:sortCodes.includes(code)
  }

  return tableField
}

export function channelIdSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: 'id',
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: true,
    isSort: true
  }

  return tableField
}

export function realeseSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    minWidth: '200px',
    isReleases: true
  }

  return tableField
}

export function datesSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const dateFullYears = ['dateFound', 'dateGiven', 'payment_createdAt', 'integration_paidAt', 'payment_paidAt', 'statisticDate', 'productSampleInfo_sendAt']
  const dateEdit = ['dateGiven', 'dateFound']
  const integrationDates = ['integration_date']
  const shortCode = ['payment_plannedPaidAt', 'integration_paidAt', 'integration_plannedPaymentDate', 'payment_paidAt', 'productSampleInfo_sendAt', 'agreement_allDecisionDate']
  const integrationPaymentsDateEdit = ['integration_plannedPaymentDate']

  const tableField: IStrategyField = {
    title: field.name,
    code: shortCode.includes(code) ? code?.split('_')?.[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isDate: true,
    isSort: sortFields.includes(field.code),
    readOnly: ['createdAt', 'paidAt', 'payment_plannedPaidAt', 'integration_paidAt'].includes(code),
    isFullYears: dateFullYears.includes(code),
    isDateEdit: dateEdit.includes(code),
    isIntegrationDatesEdit: integrationDates.includes(code),
    isIntegrationPaymentsDateEdit: integrationPaymentsDateEdit.includes(code),
    fieldDataDateCode: code === 'integration_plannedPaymentDate' ? 'plannedPaidAt' : undefined
  }

  return tableField
}

export function predictionSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const readOnly = ['prediction_cpv','prediction_roi','prediction_orders', 'prediction_traffic']
  const showRemains = ['prediction_cpv', 'prediction_cr', 'prediction_ctr']

  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isSort: sortFields.includes(field.code) ? true : false,
    isPredictions: true,
    readOnly: readOnly.includes(field.code),
    isShowRemains: showRemains.includes(code)
  }

  return tableField
}

export function releaseUsersSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: code === 'productSampleInfo_userId' ? 'user' : code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isDate: false,
    isReleaseUsers: true
  }

  return tableField
}

export function ctrsSelect(code: allAvailableField, field:ISettingsSelected):IStrategyField {
  const percentTypes = ['ctr', 'cr', 'channel_ctr', 'channel_cr', 'channel_roiPredict', 'channel_ctrPredict', 'channel_crThrough', 'crThrough']
  const rubTypes = ['cpv', 'channel_cpv']
  const roiCodes = ['roi', 'channel_roi', 'channel_roiPredict', 'channel_roiNewPredict', 'prediction_roi']
  const showRemains = ['cr', 'cpv', 'ctr', 'channel_ctr', 'channel_cr', 'channel_cpv', 'channel_ctrPredict', 'channel_crSite', 'channel_cpvPredict', 'channel_cpvNewPredict', 'channel_roiNewPredict', 'channel_crThrough', 'crThrough']
  const backgroundColorCode=['cr', 'ctr']
  const maxWidthCalc = (code: allAvailableField) => {
    if (roiCodes.includes(code)) return '75px'
    return '180px'
  }

  const tableField: IStrategyField = {
    title: field.name,
    code: code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isCtrls: true,
    ctrlsIcon: percentTypes.includes(code) ? '%' : rubTypes.includes(code) ? '₽' : '',
    minWidth: '70px',
    maxWidth: maxWidthCalc(code),
    isSort: true,
    isShowRemains: showRemains.includes(code),
    isBackgroundColor: backgroundColorCode.includes(code)
  }

  return tableField
}

export function moneySelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const readOnlyMoney = ['ordersSumm', 'cac', 'channel_cac', 'totalSumm', 'averageOrderSumm', 'paidSum']
  const channeKeys = ['channel_price', 'channel_cac']
  const showRemains = ['cac']
  const channelPriceEdit = ['initialPrice', 'channel_price']

  let currentCode: any = code
  channeKeys.includes(currentCode) ? currentCode = code.split('_')[1] : currentCode = code

  const tableField: IStrategyField = {
    title: field.name,
    code: currentCode,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isMoney: true,
    minWidth: '70px',
    readOnly: readOnlyMoney.includes(field.code) ? true : false,
    isSort: true,
    isShowRemains: showRemains.includes(code),
    isChannelPriceEdit: channelPriceEdit.includes(code)
  }

  return tableField
}

export function typesSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: 'type',
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isType: true
  }

  return tableField
}

export function linksSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const codeString = code.split('_').length === 2 ? code.split('_')[1] : code;
  const isSelfEmployedCheckLinks = codeString === 'selfEmployedCheckLink'
  const tableField: IStrategyField = {
    title: field.name,
    code: codeString,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isLinks: !isSelfEmployedCheckLinks,
    isArray: isSelfEmployedCheckLinks,
    isArrayLinks: isSelfEmployedCheckLinks,
  }

  return tableField
}

export function contactsEmailSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isContact: true,
  }

  return tableField
}

export function themeSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const sortCodes = ['theme']
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isTheme: true,
    isSort:sortCodes.includes(code)
  }

  return tableField
}

export function goodsSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isGoods: true,
  }

  return tableField
}

export function categorySelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isCategory: true
  }

  return tableField
}

export function integrationChannelSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: 'integration',
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isIntegrationChannel: true,
    maxWidth: '300px'
  }

  return tableField
}

export function paymentPriceSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const paymentPrice = ['amount', 'commission', 'paidSumm', 'totalSumm', 'payment_contractBlock_summ', 'payment_contractBlock_summAttachment']

  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isPaymentPrice: true,
    readOnly: ['commission'].includes(code) ? false : true,
    isSort: paymentPrice.includes(code)
  }

  return tableField
}

export function changeNumFieldSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const sortFields = ['by', 'kz', 'ru', 'ua', 'audience', '13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65_plus', 'woman', 'man', 'targetAudience', "subs", "notSubs", "fromPC", "fromPhone"]

  const calcMinWidth = (code: allAvailableField) => {
      return ["fromPC", "fromPhone"].includes(code) ? '140px' : code==='targetAudience' ? '80px' : '100px'
  }

  const readOnlyCode= ['targetAudience']

  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isChangeNum: true,
    isSort: sortFields.includes(field.code),
    minWidth: calcMinWidth(code),
    isPercent: code !== 'audience',
    isAudience: sortFields.includes(field.code),
    readOnly:readOnlyCode.includes(field.code)
  }

  return tableField
}

export function technicalTaskSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isTz: true,
    maxWidth: '220px'
  }

  return tableField
}

export function companyAccountSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    maxWidth: '250px',
    isCompanyAccount: true
  }

  return tableField
}

export function integrationPaidSummSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: 'paidSumm',
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isIntegrationPaidSumm: true,
    isSort: true,
  }
  return tableField
}

export function sizeSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {

  const tableField: IStrategyField = {
    title: field.name,
    code:code === 'size' ? 'channel' : 'size',
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isSize: true,
    fieldCode:code === 'size' ? 'size' : undefined
  }

  return tableField
}

export function firstIntegrationWithChannelSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isFirstIntegrationWithChannel: true,
  }

  return tableField
}

export function goodsCategoryCodeSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isGoodCategoryCode: true
  }

  return tableField
}

export function lastCommentsSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: 'posted_comments',
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isLastComments: true,
    maxWidth: '150px'
  }

  return tableField
}

export function tzSimpleStrategySelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const editIconCodes= ['tz_cta', 'tz_interactive', 'tz_positioning', 'tz_user', 'tz_authorInFrame']
  const positionCodes= ['tz_positioning']
  const tableField: IStrategyField = {
    title: field.name,
    code: code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isTzSimpleSelect: true,
    isEditIcon:editIconCodes.includes(code),
    isPositioning:positionCodes.includes(code),
  }

  return tableField
}

export function tzTextSelect(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const editCodes = ['tz_name', 'tz_link']
  const linkCodes = ['tz_link']

  const tableField: IStrategyField = {
    title: field.name,
    code: code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isTzSimple: true,
    isEdit: editCodes.includes(code),
    isTzLinks: linkCodes.includes(code),
    isTzCopy: editCodes.includes(code)
  }

  return tableField
}

export function tzSelectCheckboxStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const channels = ['tz_types']
  const goods = ['tz_goods']
  const editIconCodes= ['tz_goods']

  const tableField: IStrategyField = {
    title: field.name,
    code: code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isTzSelectChannel: channels.includes(code),
    isTzSelectGoods: goods.includes(code),
    isEditIcon: editIconCodes.includes(code)
  }

  return tableField
}

export function checkListStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {

  const minWidth = ['isPromoAndSourceInDirectory']

  const tableField: IStrategyField = {
    title: field.name,
    code: code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isCheckList: true,
    minWidth: minWidth.includes(code) ? '140px' : undefined,
    maxWidth: minWidth.includes(code) ? '140px' : undefined,
  }

  return tableField
}

export function integrationThemeStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const sortCodes = ['integration_theme']

  const tableField: IStrategyField = {
    title: field.name,
    code: code === 'integration_theme' ?'integration_theme' : 'channel',
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: false,
    isIntegrationTheame: true,
    maxWidth:"150px",
    isSort:sortCodes.includes(code)

  }

  return tableField
}

export function contractorStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const tableField: IStrategyField = {
    title: field.name,
    code: code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: true,
    isSort: sortFields.includes(field.code),
  }

  return tableField
}

export function paymentsStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const isLinks = ['payment_contractBlock_contract_link', 'payment_contractBlock_attachment_link']
  const isStatus = ['payment_status']
  const shortCode = ['payment_status',]
  const notSimpleCode = ['payment_contractBlock_contract_link', 'payment_contractBlock_attachment_link', 'payment_status']
  const maxWidthCell = ['payment_contractBlock_trackNumber', 'payment_contractBlock_contract_link', 'payment_contractBlock_attachment_link',]

  const tableField: IStrategyField = {
    title: field.name,
    code: shortCode.includes(code) ? code?.split('_')?.[1] : code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isSimple: notSimpleCode.includes(code) ? false : true,
    isContractLink: isLinks.includes(code),
    isPaymentTableStatus: isStatus.includes(code),
    maxWidth: maxWidthCell.includes(code) ? '200px' : 'auto'
  }

  return tableField
}

export function arraysStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {
  const isStatus = ['payment_integration_status', 'payment_pay_status']
  const isMoney = ['payment_pay_totalSumm', 'payment_integration_price']
  const isSimple = [
    'payment_pay_id', 'payment_pay_fio', 'payment_pay_phoneOrCard', 'payment_pay_selfEmployedCheck', 'payment_pay_source',
    'payment_pay_direction', 'payment_integration_id', 'payment_integration_firstIntegrationWithChannel', 'payment_channel_contact',
    'payment_pay_paymentType', 'ordInfo_hasAct', 'ordInfo_token'
  ]
  const isLinks = ['payment_pay_paymentLink',]
  const isPomokod = ['payment_integration_promokod',]
  const isDate = ['payment_pay_plannedPaidAt', 'payment_integration_date', 'payment_pay_paidAt']
  const maxWidthCell = ['payment_channel_contact', 'payment_pay_paymentLink', 'payment_pay_fio', 'payment_pay_phoneOrCard', 'ordInfo_token']
  const isSimpleCopy = ['ordInfo_token']

  const tableField: IStrategyField = {
    title: field.name,
    code: code,
    immutable: allImmutableField.includes(field.code as immutableTableField) ? true : false,
    isTextSimple: isSimple.includes(code),
    isSimple: false,
    isArray: true,
    isPaymentArrayStatus: isStatus.includes(code),
    isMoneySimple: isMoney.includes(code),
    isArrayLinks: isLinks.includes(code),
    isPomokod: isPomokod.includes(code),
    isDateArray: isDate.includes(code),
    maxWidth: maxWidthCell.includes(code) ? '200px' : 'auto',
    isSort: sortFields.includes(field.code),
    isSimpleCopy:isSimpleCopy.includes(code),
  }

  return tableField
}

export function guaranteeStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {

  const tableField: IStrategyField = {
    title: field.name,
    code: 'guarantee',
    immutable: false,
    isSimple: false,
    isGuarantee: true
  }

  return tableField
}

export function guaranteeCheckboxStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {

  const tableField: IStrategyField = {
    title: field.name,
    code: 'guarantee',
    immutable: false,
    isSimple: false,
    isGuaranteeCheckbox: true
  }

  return tableField
}

export function simpleInObjectCodeStrategy(code: allAvailableField, field: ISettingsSelected): IStrategyField {

  const sortCodes = ['goodsCategory']

  const tableField: IStrategyField = {
    title: field.name,
    code:code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: false,
    isSimple: false,
    isSimpleInObjectCode: true,
    isSort:sortCodes.includes(code)
  }

  return tableField
}

export function paymentCheckboxStrategy(code: allAvailableField, field:ISettingsSelected):IStrategyField {

  const tableField:IStrategyField = {
    title:field.name,
    code:'payment',
    fieldCode:code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: false,
    isSimple:false,
    isPaymentCheckbox:true,
  }

  return tableField
}

export function agrrementsAllCodesStrategy(code: allAvailableField, field:ISettingsSelected):IStrategyField {

  const simpleCodes = [
    'agreement_id', 'agreement_integration_price', 'agreement_channel_price', 'agreement_channel_roiNewPredict', 'agreement_channel_roiPredict',
    'agreement_channel_targetAudience','agreement_channel_13-17' , 'agreement_channel_18-24' , 'agreement_channel_25-34' , 'agreement_channel_35-44' ,
    'agreement_channel_45-54' , 'agreement_channel_55-64' , 'agreement_channel_allAge' , 'agreement_channel_man' , 'agreement_channel_woman' , 
    'agreement_channel_ru' , 'agreement_channel_by' , 'agreement_channel_ua' , 'agreement_channel_kz', 'agreement_channel_subs' , 'agreement_channel_notSubs' ,
    'agreement_channel_fromPhone' , 'agreement_channel_fromPC'
  ]
  const isMoneySimple = [
    'agreement_integration_price', 'agreement_channel_price'
  ]
  const isPercent = [
    'agreement_channel_roiNewPredict', 'agreement_channel_roiPredict','agreement_channel_targetAudience',
    'agreement_channel_13-17' ,'agreement_channel_18-24' ,'agreement_channel_25-34' , 'agreement_channel_35-44' ,'agreement_channel_45-54' ,
    'agreement_channel_55-64' , 'agreement_channel_allAge' , 'agreement_channel_man' , 'agreement_channel_woman' , 'agreement_channel_ru' , 'agreement_channel_by' , 'agreement_channel_ua' , 'agreement_channel_kz', 'agreement_channel_subs' , 'agreement_channel_notSubs' ,'agreement_channel_fromPhone' , 'agreement_channel_fromPC'
  ]
  const isShowRemains = [
    'agreement_channel_subs' , 'agreement_channel_notSubs' , 'agreement_channel_13-17' , 'agreement_channel_18-24' , 'agreement_channel_25-34' , 'agreement_channel_35-44' ,
    'agreement_channel_45-54' , 'agreement_channel_55-64' , 'agreement_channel_man' ,'agreement_channel_woman' , 'agreement_channel_ru' , 'agreement_channel_by' , 'agreement_channel_ua' , 'agreement_channel_kz','agreement_channel_cpvNewPredict', 'agreement_channel_cpvPredict', 'agreement_integration_ctrPredict', 'agreement_integration_crPredict', 'agreement_channel_targetAudience'
  ]
  const isCtrls = [
    'agreement_channel_cpvNewPredict', 'agreement_channel_cpvPredict', 'agreement_integration_ctrPredict', 'agreement_integration_crPredict'
  ]
  const isSimpleView = [
    'agreement_channel_audience', 'agreement_integration_viewsPredict' , 'agreement_integration_trafficPredict', 'agreement_channel_minRequests' , 'agreement_integration_ordersPredict',
  ]
  const isSort = [
    'agreement_channel_name', 'agreement_integration_date', 'agreement_createdAt'
  ]
  const isDate = [
    'agreement_integration_date', 'agreement_createdAt'
  ]
  const isChannelType = [
    'agreement_channel_name'
  ]
  const minWidth = [
    'agreement_channel_13-17' ,'agreement_channel_18-24' ,'agreement_channel_25-34' , 'agreement_channel_35-44' ,'agreement_channel_45-54' ,
    'agreement_channel_55-64'
  ]
  const isCategoryGood = [
    'agreement_integration_goodsCategory'
  ]

  const fieldCode:any = {
    agreement_id:'id', 
    agreement_channel_name:'channel',
    agreement_integration_date:'integration_date',
    agreement_integration_price:'price', 
    agreement_channel_price:'initialPrice', 
    agreement_channel_roiNewPredict:'roiNewPredict', 
    agreement_channel_roiPredict:'roiPredict',
    agreement_channel_cpvNewPredict:'cpvNewPredict',
    agreement_channel_cpvPredict:'cpvPredict',
    agreement_channel_audience:'audience',
    agreement_integration_viewsPredict:'prediction_views',
    agreement_integration_trafficPredict:'prediction_traffic',
    agreement_integration_ctrPredict:'prediction_ctr',
    agreement_integration_crPredict:'prediction_cr',
    agreement_channel_minRequests:'minRequests',
    agreement_integration_ordersPredict:'prediction_orders',
    agreement_integration_goodsCategory:'goodsCategory',
    agreement_channel_targetAudience:'targetAudience',
    'agreement_channel_13-17':'13-17', 
    'agreement_channel_18-24':'18-24', 
    'agreement_channel_25-34':'25-34', 
    'agreement_channel_35-44':'35-44',
    'agreement_channel_45-54':'45-54', 
    'agreement_channel_55-64':'55-64', 
    agreement_channel_allAge:'allAge', 
    agreement_channel_man:'man', 
    agreement_channel_woman:'woman', 
    agreement_channel_ru:'ru', 
    agreement_channel_by:'by', 
    agreement_channel_ua:'ua', 
    agreement_channel_kz:'kz', 
    agreement_channel_subs:'subs', 
    agreement_channel_notSubs:'notSubs',
    agreement_channel_fromPhone:'fromPhone', 
    agreement_channel_fromPC:'fromPC',
    agreement_createdAt:'createdAt'
  }

  const backgroundColorCode = ['agreement_integration_ctrPredict', 'agreement_integration_crPredict']

  const tableField:IStrategyField = {
    title:field.name,
    code:fieldCode[code],
    immutable: false,
    isSimple:simpleCodes.includes(code),
    isMoneySimple:isMoneySimple.includes(code),
    isPercent:isPercent.includes(code),
    isShowRemains:isShowRemains.includes(code) ? true : false,
    isCtrls:isCtrls.includes(code),
    isSimpleView:isSimpleView.includes(code),
    isSort:isSort.includes(code),
    isDate:isDate.includes(code),
    isIntegrationDatesEdit:isDate.includes(code) ? false : undefined,
    isChannelType:isChannelType.includes(code),
    isShowTypeText:isChannelType.includes(code),
    isCategoryGood:isCategoryGood.includes(code),
    maxWidth:isChannelType.includes(code) ? '180px' : undefined,
    minWidth:code === 'agreement_integration_crPredict' ? '80px' : minWidth.includes(code) ? '50px' : undefined,
    isBackgroundColor: backgroundColorCode.includes(code)
  }
  return tableField
}

export function integrationTablePaymentsStrategy(code: allAvailableField, field:ISettingsSelected):IStrategyField {

  const tableField:IStrategyField = {
    title:field.name,
    code:'payment',
    fieldCode:'token',
    immutable: false,
    isSimple:false,
    isIntegrationPayment:true,
    maxWidth:'200px'
  }

  return tableField
}

export function simpleCheckboxStrategy(code: allAvailableField, field:ISettingsSelected):IStrategyField {

  const tableField:IStrategyField = {
    title:field.name,
    code:code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: false,
    isSimple:false,
    isSimpleCheckbox:true
  }

  return tableField
}

export function productSampleStrategy(code: allAvailableField, field:ISettingsSelected):IStrategyField {

  const channel = ['productSampleInfo_channel']
  const promokod = ['productSampleInfo_integration_promokod']
  const productSample = ['productSampleInfo_productSamples']

  const tableField:IStrategyField = {
    title:field.name,
    code:'integration',
    fieldCode:code.split('_').length === 3 ? code.split('_')[2] : code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: false,
    isSimple:false,
    isChannelProductSample:channel.includes(code),
    isPromokodProductSample:promokod.includes(code),
    isProductSampleList:productSample.includes(code),
    maxWidth:channel.includes(code) ? '200px' : undefined
  }

  return tableField
}

export function tzCheckboxStrategy(code: allAvailableField, field:ISettingsSelected):IStrategyField {

  const tableField:IStrategyField = {
    title:field.name,
    code:code.split('_').length === 2 ? code.split('_')[1] : code,
    immutable: false,
    isSimple:false,
    tzCheckbox:true
  }

  return tableField
}

export function prePaymentStrategy(code: allAvailableField, field:ISettingsSelected):IStrategyField {

  const simpleCodes = ['prepayment_id',]
  const isDate = ['prepayment_integration_date','prepayment_paidAt']
  const isStatus = ['prepayment_status','prepayment_paymentStatus']
  const isChannelType = ['prepayment_channel']
  const widthCode = ['prepayment_channel','prepayment_price','prepayment_releaseUser']
  const isReleaseUsers = ['prepayment_releaseUser']
  const isIntegrationPaymentStatus= ['prepayment_paymentStatus',]
  const isIntegrationPaidSumm = ['prepayment_paidSum','prepayment_price',]
  const dateFullYears = [ 'prepayment_paidAt']


  const fieldCode:any = {
    prepayment_id:'id',
    prepayment_channel:'channel',
    prepayment_integration_date:'integration_date',
    prepayment_price:'price',
    prepayment_paidAt:'paidAt',
    prepayment_releaseUser:'releaseUser',
    prepayment_paymentStatus: 'paymentStatus',
    prepayment_status:'status',
    prepayment_paidSum:'paidSum'
  }

  const tableField:IStrategyField = {
    title:field.name,
    code:fieldCode[code],
    immutable: false,
    isSimple:simpleCodes.includes(code),
    isDate:isDate.includes(code),
    isFullYears:dateFullYears.includes(code),
    isIntegrationDatesEdit:isDate.includes(code) ? false : undefined,
    isChannelType:isChannelType.includes(code),
    isShowTypeText:isChannelType.includes(code),
    isStatuses: isStatus.includes(code),
    maxWidth:widthCode.includes(code) ? '140px' : undefined,
    isReleaseUsers:isReleaseUsers.includes(code),
    isIntegrationPaymentStatus:isIntegrationPaymentStatus.includes(code),
    isIntegrationPaidSumm: isIntegrationPaidSumm.includes(code)
  }
  return tableField
}

